@import ../../styles/helpers

.section
    +d
        padding-top: 112px
    +t
        padding-top: 64px

.head
    margin-bottom: 80px
    text-align: center
    +m
        margin-bottom: 48px

.title
    margin-bottom: 20px

.info
    max-width: 545px
    margin: 0 auto 40px
    color: $neutrals3
    +dark
        color: $neutrals6

.nav
    display: flex
    flex-wrap: wrap
    justify-content: center
    margin: -12px -6px 0
    +m
        display: none

.dropdown
    display: none
    +m
        display: block

.btn
    margin: 12px 6px 0
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $neutrals4
    transition: all .2s
    &:hover
        color: $neutrals3
        +dark
            color: $neutrals6
    &.active
        background: $neutrals3
        color: $neutrals8
        +dark
            background: $neutrals8
            color: $neutrals2

.wrap
    +m
        position: relative
        padding-bottom: 72px

.slider
    display: flex
    flex-wrap: wrap
    margin: -60px -16px 0
    +m
        position: static
        display: block
        margin: 0

.slide
    flex: 0 0 calc(33.333% - 32px)
    width: calc(33.333% - 32px)
    margin: 60px 16px 0
    +m
        width: auto
        margin: 0

// Additional styles for the cards
.cardContainer
    display: flex
    justify-content: space-between
    flex-wrap: wrap // Allow cards to wrap to next line if necessary

.cardColumn
    flex: 0 0 calc(33.333% - 32px)
    width: calc(33.333% - 32px)
    margin: 0

.card
    border: 1px solid #ccc
    border-radius: 10px
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)
    padding: 16px
    text-align: center

.cardImage
    max-width: 4rem
    margin: 0 auto
    
.cardTitle
    margin-top: 10px
    
.cardParagraph
    margin-top: 10px

// Responsive styling
@media screen and (max-width: 768px)
    .cardColumn
        flex: 0 0 100%
        width: 100%
        margin: 0


