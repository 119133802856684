@import ../../styles/helpers

.modal
  display: flex
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  padding: 70px
  background: rgba($neutrals1,.8)
  opacity: 0
  animation: showModal .4s forwards
  overflow: auto
  z-index: 999
  +m
    padding: 48px 32px
  +dark
    background: rgba($neutrals1,.9)

@keyframes showModal
  0%
    opacity: 0
  100%
    opacity: 1

.outer
  position: relative
  width: 100%
  max-width: 580px
  margin: auto
  background: $neutrals8
  border-radius: 16px
  z-index: 2
  +dark
    background: $neutrals1
    box-shadow: inset 0 0 0 1px $neutrals3

.container
  padding: 64px 64px 50px
  +m
    padding: 48px 16px 32px

.close
  position: absolute
  top: -16px
  right: -16px
  width: 32px
  height: 32px
  background: $neutrals8
  box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.2)
  border-radius: 50%
  font-size: 0
  +dark
    box-shadow: inset 0 0 0 1px $neutrals3
    background: $neutrals1
  svg
    fill: $neutrals2
    transition: transform .2s
    +dark
      fill: $neutrals4
  &:hover
    svg
      transform: rotate(90deg)
